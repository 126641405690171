import { GlobalHoverService } from 'o365-vue-services';
import OrgUnitRolesHover from 'system.libraries.vue.components.OrgUnitRolesHover.vue';

export default {
    mounted(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        service.bindElement(el, {
            component: OrgUnitRolesHover,
            props: {
                roleId: binding.value.roleId,
                orgUnitId: binding.value.orgUnitId,
                dataObject: binding.value.dataObject,
                whereClause: binding.value.whereClause
            },
        })
    },
    updated(el: HTMLElement, binding: any) {
        const service = GlobalHoverService.getService();
        const props = service.getPropsFromEl(el);

        if (props && props.roleId !== binding.value.roleId) {
            props.roleId = binding.value.roleId;
            props.orgUnitId = binding.value.orgUnitId;
        }

        if (props && props.dataObject !== binding.value.dataObject) {
            props.dataObject = binding.value.dataObject;
        }

        if (props && props.whereClause !== binding.value.whereClause) {
            props.whereClause = binding.value.whereClause;
        }
    },
    unmounted(el: HTMLElement, _binding: any) {
        const service = GlobalHoverService.getService();
        service.unbindElement(el);
    }
};